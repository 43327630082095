import '../src/shared/bootstrap341.min.js'
import 'controllers'

//import Rails from "@rails/ujs"

// Make accessible for Electron and Mobile adapters
//window.Rails = Rails

require("@rails/activestorage").start()
// require("channels")
// require("trix")
// require("@rails/actiontext")

// css
import 'stylesheets/shop_templates_v1/base.scss'

import '../src/shared/hashcash.js'
import '../src/shared/jquery-ujs.js'
import '../src/shared/active_storage_direct_upload.js'
import '../src/shared/delete_confirm_modal.js'
import '../src/shared/auto_numeric_min.js'

import '../src/shop_templates/v1/correios_addresses.js'
import '../src/shop_templates/v1/product_quantity.js'
import '../src/shop_templates/v1/js.cookie.js'
import '../src/shop_templates/v1/orders.js'
import '../src/shop_templates/v1/paypal_gateway.js'
import '../src/shop_templates/v1/select2.full.min.js'
import '../src/shop_templates/v1/select2.pt-BR.js'
import '../src/shop_templates/v1/jquery.mCustomScrollbar.concat.min.js'
import '../src/shop_templates/v1/jquery.parallax.js'
import '../src/shop_templates/v1/back-to-top.js'
import '../src/shop_templates/v1/jquery.waypoints.js'
import '../src/shop_templates/v1/jquery.counterup.min.js'
import '../src/shop_templates/v1/jquery.imagezoom.min.js'
import '../src/shop_templates/v1/jquery.fancybox.js'
import '../src/shop_templates/v1/jquery.countdown.min.js'
import '../src/shop_templates/v1/jquery.slickmodal.js'

// owl carousel
import "../src/shop_templates/v1/owl.carousel.js"
import '../src/shop_templates/v1/owl.carousel.loader.js'

// shared
import '../src/shared/jquery-ui.min.js';

require("jquery-migrate")

//Rails.start()

window.variationRadioClick = function(url, id){
  var f1 = $('ul#ul-product-feature1 input[name=feature1]:checked').val();
  var f2 = $('ul#ul-product-feature2 input[name=feature2]:checked').val();
  var f3 = $('ul#ul-product-feature3 input[name=feature3]:checked').val();
  if ((f1 == undefined)||(f1 == "")){ f1 = ""; } else {f1 = "/"+f1  }
  if ((f2 == undefined)||(f2 == "")){ f2 = ""; } else {f2 = "/"+f2  }
  if ((f3 == undefined)||(f3 == "")){ f3 = ""; } else {f3 = "/"+f3  }
  $("#label-feature-"+id).html($("#label-feature-"+id).text()+" <i class='fa fa-spinner fa-spin'></i>");
  window.open(""+url+""+f1+""+f2+""+f3, "_self");
}


window.photo_thumbnail_click = function(i){
  $('i#product-view-photo-loading').show();
  $('#product-view-photo-zoom').hide();
  if ($('#product-thumbnail-'+i).length == 0){
    $('i#product-view-photo-loading').hide();
  } else {
    $('#product-view-photo').on('load',function(){
      $('#product-view-photo-zoom').attr('href', $('#product-thumbnail-'+i).data('large-photo'));
      $('#product-view-photo').ImageZoom({showDescription: false, bigImageSrc: $('#product-thumbnail-'+i).data('large-photo')});
      $('i#product-view-photo-loading').hide(); $('#product-view-photo-zoom').show();
    }).attr('src', $('#product-thumbnail-'+i).data('medium-photo'));
  }
  return false;
}

window.App = function () {

  function handleBootstrap() {
    /*Bootstrap Carousel*/
    jQuery('.carousel').carousel({
      interval: 15000,
      pause: 'hover'
    });

    /*Tooltips*/
    jQuery('.tooltips').tooltip();
    jQuery('.tooltips-show').tooltip('show');
    jQuery('.tooltips-hide').tooltip('hide');
    jQuery('.tooltips-toggle').tooltip('toggle');
    jQuery('.tooltips-destroy').tooltip('destroy');

    /*Popovers*/
    jQuery('.popovers').popover();
    jQuery('.popovers-show').popover('show');
    jQuery('.popovers-hide').popover('hide');
    jQuery('.popovers-toggle').popover('toggle');
    jQuery('.popovers-destroy').popover('destroy');
  }


  function handleToggle() {
    jQuery('.list-toggle').on('click', function() {
      jQuery(this).toggleClass('active');
    });
  }

  function handleBoxed() {
    jQuery('.boxed-layout-btn').click(function(){
      jQuery(this).addClass("active-switcher-btn");
      jQuery(".wide-layout-btn").removeClass("active-switcher-btn");
      jQuery("body").addClass("boxed-layout container");
    });
    jQuery('.wide-layout-btn').click(function(){
      jQuery(this).addClass("active-switcher-btn");
      jQuery(".boxed-layout-btn").removeClass("active-switcher-btn");
      jQuery("body").removeClass("boxed-layout container");
    });
  }

  function handleHeader() {
    jQuery(window).scroll(function() {
      if (jQuery(window).scrollTop()>50){
        jQuery(".navbar-v2-flex-fixed-wide").addClass("visible");
        jQuery(".navbar-v2-flex-fixed-boxed").addClass("visible");
        // jQuery(".header-fixed").addClass("header-fixed-body-shrink");
        // jQuery(".header-fixed .header-static").addClass("header-fixed-shrink");
      }
      else {
        jQuery(".navbar-v2-flex-fixed-wide").removeClass("visible");
        jQuery(".navbar-v2-flex-fixed-boxed").removeClass("visible");
        // jQuery(".header-fixed").removeClass("header-fixed-body-shrink");
        // jQuery(".header-fixed .header-static").removeClass("header-fixed-shrink");
      }
    });
  }

  function handleMegaMenu() {
    $(document).on('click', '.mega-menu .dropdown-menu', function(e) {
      e.stopPropagation()
    })
  }


  return {
    init: function () {
      handleBootstrap();
      handleToggle();
      handleBoxed();
      handleHeader();
      handleMegaMenu();

      jQuery('input[data-role=money]').autoNumeric('init', { aSep: '', aDec: ',' } );

    },

    initScrollBar: function () {
      jQuery('.mCustomScrollbar').mCustomScrollbar({
        theme:"minimal",
        scrollInertia: 300,
        scrollEasing: "linear"
      });
    },

    initCounter: function () {
      jQuery('.counter').counterUp({
        delay: 10,
        time: 1000
      });
    },

    initParallaxBg: function () {
      jQuery('.parallaxBg').parallax("50%", 0.2);
      jQuery('.parallaxBg1').parallax("50%", 0.4);
    }
  };

}();
