$( function() {
  $(".correios_addresses_zip_btn").on("click",function(event){
    event.preventDefault();
    var fieldset_id = $(this).parent().siblings('.placeholder_correios_addresses_zip').data('fieldset-id');
    var zip_field = $(this).parent().siblings('.placeholder_correios_addresses_zip');
    if (fieldset_id == undefined){
      fieldset_id = $(this).parent().siblings('.field_with_errors').children(".placeholder_correios_addresses_zip").data('fieldset-id');
      zip_field = $(this).parent().siblings('.field_with_errors').children(".placeholder_correios_addresses_zip");
    }

    if (fieldset_id != undefined){
      if (zip_field.val() == ""){
        zip_field.focus();
        return false;
      } else {
        ajax_correios_addresses_search(zip_field.data('fieldset-id'));
      }
    }
  });

  $(".placeholder_correios_addresses_zip").on("keypress",function(event){
    if (event.keyCode == 13){
      event.preventDefault();
      if ($(this).val() == ""){
        $(this).focus();
      } else {
        var fieldset_id = $(this).data('fieldset-id');
        ajax_correios_addresses_search(fieldset_id);
        $.rails.disableElement($("#"+fieldset_id+" a.correios_addresses_zip_btn"));
      }
    }
  });
});

window.ajax_correios_addresses_search = function(s){
  $.getScript("/s/correios_addresses/"+$("#"+s+" .placeholder_correios_addresses_zip").val()+"/?fieldset_id="+s);
}

