import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [ 'input', 'countValue' ]

  initialize() {
    this.maxValue     = this.data.get('max-value')     || '200'
    this.warningLimit = this.data.get('warning-limit') || '10'
    this.warningClass = this.data.get('warning-class') || 'text-red-500'
    this.update()
  }

  update() {
    var count = this.inputTarget.value.length
    var message = `${count}/${this.maxValue}`

    if (count > this.maxValue) {
      this.inputTarget.value = this.inputTarget.value.substring(0, this.inputTarget.value.length - 1)
      count = this.inputTarget.value.length
      message = `${count}/${this.maxValue}`
    }

    count > this.maxValue - this.warningLimit ? this.addWarningClass() : this.removeWarningClass()
    this.setMessage(message)
  }

  setMessage(message) {
    this.countValueTarget.textContent = message
  }

  addWarningClass() {
    this.countValueTarget.classList.add(this.warningClass)
  }

  removeWarningClass() {
    this.countValueTarget.classList.remove(this.warningClass)
  }

}