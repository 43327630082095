import { Controller } from "stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ["input", "image", "info", "progress"]

  changed() {
    Array.from(this.inputTarget.files).forEach(file => {

      // Reseta barra de progresso
      this.showProgressBar()
      this.defineProgressBarPercent(0)
      this.progressTarget.classList.remove("bg-success")
      this.progressTarget.classList.remove("bg-danger")

      const upload = new DirectUpload(file, this.postURL(),{
        directUploadWillStoreFileWithXHR: (xhr) => {
          xhr.setRequestHeader('x-amz-acl', 'public-read');
          xhr.upload.addEventListener("progress", event => this.instantDirectUploadDidProgress(event))
        }
      })

      upload.create((error, blob) => {
        if (error) {
          this.progressTarget.classList.add("bg-danger")
        } else {
          this.hiddenInput().value = blob.signed_id
          // this.inputTarget.type = "hidden"
          this.imageTarget.src = `${this.data.get('cdn')}/${blob.key}`
          this.progressTarget.classList.add("bg-success")
          if (this.isImage(blob.content_type)){
            this.imageTarget.style.display = 'block'
          } else {
            this.infoTarget.style.display = 'block'
          }
        }
        setTimeout(this.hideProgressBar(), 5000)
      })
    })
  }

  instantDirectUploadDidProgress(event) {
    this.defineProgressBarPercent(Math.trunc(((event.loaded * 100) / event.total)))
  }

  defineProgressBarPercent(percent){
    this.progressTarget.style.width = `${percent}%`
  }

  hideProgressBar(){
    this.progressTarget.parentElement.style.display = 'none'
  }

  showProgressBar(){
    this.infoTarget.style.display = 'none'
    this.imageTarget.style.display = 'none'
    this.progressTarget.parentElement.style.display = 'flex'
  }

  hiddenInput() {
    if (this._hiddenInput == undefined ) {
      this._hiddenInput = document.createElement('input')
      this._hiddenInput.name = this.inputTarget.name
      this._hiddenInput.type = "hidden"
      this.inputTarget.parentNode.insertBefore(this._hiddenInput, this.inputTarget.nextSibling)
    }
    return this._hiddenInput
  }

  postURL() {
    return '/rails/active_storage/direct_uploads'
  }

  isImage(contentType) {
    return ["image/jpeg", "image/gif", "image/png"].includes(contentType);
  }

}