addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename">${file.name}</span>
    </div>
  `)
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})


// Atribuir evento "onchange" em campos "input" do tipo "file", que tiverem a classe "upload_field_with_thumbnail".
// Quando o usuário clicar na imagem para fazer o upload, então, logo após a escolha do arquivo, a imagem será
// substituída por outra com a mensagem "Salve para enviar". Após salvar o formulário, então o upload efetivamente
// ocorre, enviando para o bucket do CDN e seguindo o restante do fluxo.
//
document.addEventListener("DOMContentLoaded", function(){

  let listInputUploadWithThumbnail = document.querySelectorAll(".upload_field_with_thumbnail")
  if (listInputUploadWithThumbnail != undefined){
    listInputUploadWithThumbnail.forEach(function(inputUploadWithThumbnail) {

      inputUploadWithThumbnail.addEventListener('change', (event) => {

        let thumb = document.querySelector("#" + event.target.id + "_thumbnail")
        if (thumb != undefined){
          thumb.src = thumb.dataset["saveToSend"]
        }

      })

    })
  }

});