import { Controller } from "stimulus"

export default class extends Controller {

  static targets = [
                      "addRangeButton",
                      "zipRangeFields"
                    ]

  initialize(){
  }

  toggle(event) {
    event.preventDefault()
    this.addRangeButtonTarget.classList.add("d-none")
    this.zipRangeFieldsTarget.classList.remove("d-none")
  }

}
