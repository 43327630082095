window.subtractQty = function(){
    if(document.getElementById("qty").value - 1 < 0)
        return;
    else
        document.getElementById("qty").value--;
}

window.subtractQty1 = function(){
    if(document.getElementById("qty1").value - 1 < 0)
        return;
    else
        document.getElementById("qty1").value--;
}

window.subtractQty2 = function(){
    if(document.getElementById("qty2").value - 1 < 0)
        return;
    else
        document.getElementById("qty2").value--;
}

window.subtractQty3 = function(){
    if(document.getElementById("qty3").value - 1 < 0)
        return;
    else
        document.getElementById("qty3").value--;
}

window.subtractQty4 = function(){
    if(document.getElementById("qty4").value - 1 < 0)
        return;
    else
        document.getElementById("qty4").value--;
}