import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["links", "template" ]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
  }

  add_association(event) {
    event.preventDefault();
    let timestamp = new Date().getTime()
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, timestamp)
    this.linksTarget.insertAdjacentHTML('beforebegin', content)

    document.getElementById(this.data.get("setfocus").replace("NEW_RECORD", timestamp)).focus()
  }

  remove_association(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.classList.add('d-none')
    }
  }

}